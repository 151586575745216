.trash-infographic {
  &__main {
    display: flex;
    flex-flow: column;
    justify-content: center;

    @include media-breakpoint-up(sm) {
      &::before {
        content: '';
        display: block;
        background: var(--bs-primary);
        width: 10%;
        height: 1px;
        transform: translate(calc(-100% - 30px), 0);
        position: absolute;
      }
    }
  }
  &__figure {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.scaled-trashbag {
  max-width: 300px;
  transform: scale(var(--size-percentage));
}

.infograpic-section {
  border-radius: 2em 2em 0 0;
}
