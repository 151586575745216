.footer {
  @include get-font-size('body-lg');
  @include media-breakpoint-down(md) {
    @include get-font-size('body-md');
  }

  &__contact {
    background: var(--bs-primary-100);

    &__header {
      text-align: center;
      padding-bottom: 1ex;
      display: flex;
      align-items: center;

      @include media-breakpoint-up(md) {
        text-align: left;
        margin-bottom: 0;
      }
    }
  }

  &__end {
    background-color: var(--bs-secondary-200);

    @include media-breakpoint-down(md) {
      text-align: center;
    }

    &__header {
      @include media-breakpoint-up(md) {
        border-right: 1px solid var(--bs-secondary-300);
      }
    }
  }

  &__nav {
    &-list {
      gap: 2rem;

      @include media-breakpoint-down(md) {
        margin-top: spacer(3);
        justify-content: center;
        flex-wrap: nowrap;
        width: 100%;
      }

      button:hover,
      a:hover {
        text-decoration: underline;
      }
    }
  }

  &__contact-list {
    gap: spacer(4);

    @include media-breakpoint-down(md) {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
    @include media-breakpoint-up(md) {
      gap: spacer(7);
    }

    &__item {
      @include media-breakpoint-down(md) {
      }
      .avatar {
        width: 140px;
        border: 6px solid var(--bs-primary-100);
        outline: 1px var(--bs-primary) solid;

        @include media-breakpoint-down(md) {
          width: 80px;
          border-width: 4px;
        }
      }
    }
  }

  &__contact-card {
    align-items: center;
    gap: 2ex;

    &__function {
      font-size: 1rem;
    }

    &__main {
      @include media-breakpoint-down(md) {
        flex: 1;
        width: 200px;
      }
    }
  }
}
