$border-corner-options: (
  'tl': 'border-top-left-radius',
  'tr': 'border-top-right-radius',
  'bl': 'border-bottom-left-radius',
  'br': 'border-bottom-right-radius',
);

@each $corner, $prop in $border-corner-options {
  .rounded-#{$corner} {
    #{$prop}: $border-radius-xxl;
    @include media-breakpoint-down(md) {
      #{$prop}: $border-radius-xl;
    }
  }
  .rounded-#{$corner}-0 {
    #{$prop}: 0 !important;
  }
  .rounded-#{$corner}-md-0 {
    @include media-breakpoint-up(md) {
      #{$prop}: 0 !important;
    }
  }
  .rounded-xl {
    border-radius: $border-radius-xl;
  }
}
