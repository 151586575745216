.post-wall {
  &__main {
    position: relative;

    &::before {
      @include abs(50%, null, null, 0);
      @include size(100%, 100%);

      @include media-breakpoint-up(md) {
        height: calc(100% - 340px);
      }

      transform: translateY(-50%);
      content: '';
      pointer-events: none;
      background-color: var(--bs-primary-100);
    }

    .container {
      position: relative;
    }

    &__form-item {
      position: relative;
      background-color: var(--bs-light);
      min-height: 300px;
      border-radius: $border-radius-xl;
      border: 1px solid var(--bs-gray-200);
      overflow: hidden;
      @include media-breakpoint-down(md) {
        margin-top: spacer(4);
      }
    }
  }
}
.post-form-block {
  max-width: 600px;
  margin: 0 auto;
}
