.app .fstr-cookie-notice {
  --cookie-notice-bg: var(--bs-primary);
  max-width: 600px;
  border-width: 2px;
  border-radius: 15px;
  padding: $spacer * 2;
  color: var(--bs-light);
  border: none;
  &::before {
    background-image: linear-gradient(
        0deg,
        hsla(var(--bs-blue-800-h), var(--bs-blue-800-s), var(--bs-blue-800-l), 0.3) 0%,
        hsla(var(--bs-blue-800-h), var(--bs-blue-800-s), var(--bs-blue-800-l), 0.3) 100%
      ),
      radial-gradient(137% 51% at 72% 95%, var(--bs-blue-800) 0%, transparent 58.79%), url(/assets/gfx/halftone-sand.png);
    z-index: -1;
    opacity: 0.3;
    content: '';
    background-size: cover;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  .button-group {
    margin-top: 10px;

    .button--reject {
      display: none;
    }
  }

  .fstr-cookie-notice__title {
    @extend .h5;
    margin-bottom: 2ex;
    color: var(--bs-light);
    font-weight: bold;
  }

  .fstr-cookie-notice__toggle-row__content,
  .fstr-cookie-notice__content {
    font-size: 0.85em;
  }

  .fstr-cookie-notice__toggle-row__content.open {
    padding: 1ex 0;
  }

  .toggle-button {
    margin-left: 1ex;
    @include size(auto, auto);
    background-color: transparent;
    fill: theme-color(light);
    color: theme-color(light);
    padding: 0;

    span:first-of-type {
      display: none;
    }

    .chevron {
      margin-left: 0;
    }
  }

  .button-group {
  }

  .button {
    @extend .button--sm;
    margin-bottom: 0 !important;
    text-align: center;
    justify-content: center;
  }

  .button--settings {
    border: 2px solid;
    color: var(--bs-light);
    background-color: transparent;
    @include media-breakpoint-down(sm) {
      margin-bottom: 15px !important;
    }
  }

  .button--primary {
    background-color: var(--bs-secondary);

    &:hover {
      background-color: var(--bs-secondary);
    }
  }

  .button--close {
    @include abs($spacer, 0, null, null);
    @include size(40px, 40px);
    font-size: 0px;
    // border-color: var(--bs-secondary);
    background-color: transparent;
    padding: 0;
    // border: 2px solid;
    color: var(--bs-light);

    &::after,
    &::before {
      @include abs(50%, null, null, 50%);
      content: '';
      width: 18px;
      height: 3px;
      border-radius: 2px;
      background-color: currentColor;
    }

    &::after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }

    &::before {
      transform: translate(-50%, -50%) rotate(45deg);
    }
  }
}

.embed-removed {
  position: relative;
  padding-top: 56.25%;
  background-color: var(--bs-secondary-100);
  margin-bottom: 3ex;

  &__inner {
    @include abs(0, 0, 0, 0);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    flex-wrap: wrap;
    text-align: center;

    @include media-breakpoint-down(sm) {
      height: auto;
    }
  }

  .fstr-cookie-btn.button {
    font-size: 0.9em;
    margin-top: 3ex;
    background-color: var(--bs-primary);
  }
}
