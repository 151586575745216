.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: var(--form-field-background-color);
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  border: 1px solid var(--form-field-border-color);
  border-radius: var(--form-border-radius);

  .mdc-line-ripple {
    display: none;
  }
}

.mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--form-field-text-color); // placeholder
}

.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
  color: var(--bs-body-color); // value
}

.mat-mdc-form-field,
.mat-mdc-form-field-infix {
  width: 100%;
}
