.statistic-form {
  .statistic-overview & {
    border-width: 0;
  }
  .alert {
    font-size: 12px;
  }

  .mat-mdc-form-field-error,
  .mat-mdc-form-field-hint {
    color: var(--bs-light);
    font-weight: 700;
  }

  .mat-mdc-form-field-hint-wrapper {
    position: relative;

    &::before {
      display: none;
    }
  }

  .mat-mdc-form-field-hint {
    margin-top: -3ex;
    margin-bottom: 0;
    &::before {
      display: none;
    }
  }
}
