.form {
  --bs-gutter-x: 10px;
  --bs-gutter-y: 15px;

  &__fieldset {
    .row {
      --bs-gutter-x: 10px;
      --bs-gutter-y: 15px;
    }
  }

  // easy base variables used as default and or fallback
  --form-field-text-color: #a3a2a2;
  --form-field-border-color: #cfcfcf;
  --form-field-background-color: white;
  --form-border-radius: 5px;
}
