.button-chip {
  background: transparent;
  color: var(--bs-dark);
  padding-left: 0;
  padding-right: 0;
  border-radius: 0;
  gap: 1rem;

  fstr-icon {
    @extend .chip;
  }
  span + fstr-icon {
    margin-inline: 0px !important;
  }

  @media (hover: hover) {
    &:hover {
      background: transparent;
      color: var(--button-background-darker);
    }
  }
}
