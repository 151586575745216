.comments-list {
  .alert {
    display: none;
  }
  .comments-field {
    .label {
      display: none;
    }
    .custom-field__field {
      width: 100%;
    }
    .ql-container {
      min-height: 50px;
      border-color: var(--bs-light);
      font-family: inherit;
      background-color: var(--bs-light);
      border-radius: 0 0 $border-radius-xl $border-radius-xl;
    }

    .custom-field__error-wrapper {
      font-size: 12px;
      display: none;
    }

    .ql-editor {
      font-size: 14px;

      p,
      ul,
      ol {
        font-size: inherit;
      }
    }

    .ql-toolbar.ql-snow {
      border-color: var(--bs-gray-200);
      border-width: 0 0 1px 0;
      //      margin-top: -5px;
      background-color: var(--bs-light);
      border-radius: $border-radius-xl $border-radius-xl 0 0;
    }
  }

  &__main {
    max-height: 0px;
    overflow: hidden;
    transition: max-height 0.6s cubic-bezier(0, 1, 0, 1);

    &.open--comments {
      max-height: 10000px;
      transition: max-height 1s ease-in-out;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: spacer(3);
    justify-content: flex-start;
    align-items: flex-start;
    padding-bottom: 10px;
  }
  &__list__item {
    min-width: 50%;
    max-width: 90%;
    padding-left: 38px;

    &.set--user {
      margin-left: auto;
      margin-right: 0;
    }
  }
}
