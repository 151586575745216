.fr-video {
  position: relative;
  display: block;
  width: 100%;
  padding-top: 56.25%;
  margin-bottom: 2ex;

  iframe {
    @include abs(0, 0, 0, 0);
    @include size(100%);
  }
}

iframe,
object,
embed {
  width: 100%;
  height: auto;
}
