body {
  --quote-bg: var(--bs-primary-100);
  --quote-color: var(--bs-primary);
  --quote-secondary: var(--bs-tertiary);
}
.quote-card {
  background-color: var(--quote-bg);
  position: relative;

  &::after,
  &::before {
    @include abs(0, null, null, null);

    font-size: 20rem;
    color: var(--bs-light);
    content: '“';
    pointer-events: none;
    line-height: 1;
    transform: translateY(-3rem);

    @include media-breakpoint-down(md) {
      font-size: 14rem;
      transform: translateY(-1.7rem);
    }
  }

  &::after {
    right: spacer(5);
  }

  &__text {
    color: var(--quote-color);
    line-height: 135%;
  }

  &__footer {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .avatar {
      @include size(140px);
      border-radius: 0;
      padding: 1rem;
      background-color: var(--bs-light);
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      margin-bottom: -80px;

      @include media-breakpoint-down(md) {
        @include size(100px);
        padding: 0.8rem;
        margin-bottom: -40px;
      }
    }
  }

  &__author {
    font-family: $font-family-handwriting;
    color: var(--quote-secondary);
    font-weight: 100;
    display: flex;
    align-items: center;
    gap: 1ex;

    &::before {
      width: 2ex;
      border-top: 2px solid;
      content: '';
    }
  }
}
