.comment-card {
  padding: spacer(2);
  border-radius: $border-radius;
  position: relative;

  &::before {
    @include abs(15px, null, null, 0);
    content: '';
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-right: 5px solid var(--bs-light);
    transform: translate(-100%, -50%);
    filter: drop-shadow(-1px 0px 1px rgba(0, 0, 0, 0.1));
  }

  &__avatar .avatar {
    @include abs(0, null, null, -#{spacer(2)});
    transform: translate(-100%, 0);
  }

  &__main {
    // padding-left: calc(30px + #{spacer(2)});
    p,
    ul,
    ol {
      font-size: inherit;
    }
  }
}
