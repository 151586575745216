%button-reset {
  overflow: visible;
  padding: 0;
  background: none;
  color: inherit;
  font: inherit;
  line-height: normal;
  border: 0;
  outline: none;
  display: inline-block;
  cursor: pointer;
  -webkit-appearance: none; /* for input */
  user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none; /* for button */

  &:focus,
  &:hover {
    outline: none;
    text-decoration: none;
  }
}

button,
input[type='button'] {
  @extend %button-reset;
}
