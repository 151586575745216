.statistic-overview {
  .card {
    border: 1px solid var(--bs-gray-300);
    box-shadow: none;
  }
  &__header__info {
    max-width: 400px;
    gap: spacer(3);

    &__icon {
      @include size(64px);
      padding: spacer(2);
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--bs-light);
      background-color: var(--bs-primary);
      transform: translateY(-15%);

      @include media-breakpoint-down(md) {
        @include size(40px);
      }
    }

    &__text {
      flex: 1;
    }
  }

  &__main {
    position: relative;
    --spacer-top: 170px;

    &.platform--data {
      &::before {
        @include abs(#{var(--spacer-top)}, null, null, 0);
        @include size(100%, calc(120% - #{var(--spacer-top)}));
        content: '';
        background-color: var(--bs-primary-100);
        pointer-events: none;
        display: block;
        z-index: -1;
      }
    }
  }
}

body {
}
