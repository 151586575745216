.flip-card {
  background-color: transparent;
  width: 100%;
  height: var(--grid-items-height, 350px);
  perspective: 1000px;

  &__inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
  }

  // Enable this if we doen het linkje naar post backlogitem
  //@media (hover: hover) {
  &:hover &__inner {
    transform: rotateY(180deg);
  }
  //}

  //!TEMP
  //&__inner {
  //  transform: rotateY(180deg);
  //}

  &__front,
  &__back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    overflow: hidden;
  }

  &__front {
  }

  &__back {
    background-color: var(--bs-pink-200);
    transform: rotateY(180deg);
  }
}

.post-image-card {
  --border-radius-strength: 2.5rem;
  --border-radius: 0rem var(--border-radius-strength);

  &__front {
    position: relative;
    border-radius: var(--border-radius);
    overflow: hidden;
    height: 100%;

    fstr-image {
      display: contents;
    }

    .avatar {
      position: relative;
    }
    img {
      @include abs(0);
      object-fit: cover;
      height: 100%;
    }

    &__author {
      display: flex;
      gap: spacer(2);
      align-items: center;
      @include abs(auto, 0, 0, 0);
      z-index: 2;
      padding: calc(var(--border-radius-strength) / 2);
    }
  }

  &__back {
    border-radius: var(--border-radius);
    overflow: hidden;
    text-align: start;
    padding: calc(var(--border-radius-strength) / 2);

    &__text {
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;

      @include media-breakpoint-down(md) {
        -webkit-line-clamp: 3;
      }
    }
  }
}

li:nth-child(odd) .post-image-card {
  --border-radius: var(--border-radius-strength) 0rem;
}
