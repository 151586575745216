.wall-posts-grid {
  @include media-breakpoint-up(sm) {
    column-count: 2;
    column-gap: spacer(4);

    &__item {
      display: inline-block;
      width: 100%;

      //&:nth-child(1) {
      //  margin-top: 60px;
      //}
    }
  }
}
