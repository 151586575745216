.content-block-quotes {
  &__main .row {
    --bs-gutter-x: 2.5rem;
    --bs-gutter-y: 2.5rem;
  }

  &__list {
    gap: 4ex;

    &.is--even {
      .content-block-quotes__list__item {
        &:nth-child(3n + 1) {
          --quote-bg: var(--bs-primary-100);
          --quote-color: var(--bs-primary);
          --quote-secondary: var(--bs-tertiary);
        }

        &:nth-child(3n + 3) {
          --quote-bg: var(--bs-tertiary-200);
          --quote-color: var(--bs-tertiary);
          --quote-secondary: var(--bs-primary);
        }

        &:nth-child(3n + 2) {
          --quote-bg: var(--bs-secondary-200);
          --quote-color: var(--bs-secondary);
          --quote-secondary: var(--bs-primary);
        }
      }
    }

    &.is--uneven {
      .content-block-quotes__list__item {
        &:nth-child(3n + 1) {
          --quote-bg: var(--bs-tertiary-200);
          --quote-color: var(--bs-tertiary);
          --quote-secondary: var(--bs-primary);
        }

        &:nth-child(3n + 3) {
          --quote-bg: var(--bs-secondary-200);
          --quote-color: var(--bs-secondary);
          --quote-secondary: var(--bs-primary);
        }

        &:nth-child(3n + 2) {
          --quote-bg: var(--bs-primary-100);
          --quote-color: var(--bs-primary);
          --quote-secondary: var(--bs-tertiary);
        }
      }
    }
  }
}
