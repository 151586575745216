.flex-gap {
  &-1 {
    gap: spacer(1);
  }
  &-2 {
    gap: spacer(2);
  }
  &-3 {
    gap: spacer(3);
  }
}
