app-content-block-list-item {
  display: contents;
}

.block-list-item {
  --count-size: 60px;
  --list-height: 0%;
  position: relative;
  display: flex;
  min-height: 25vh;
  padding-bottom: spacer(8);

  @include media-breakpoint-down(md) {
    --count-size: 40px;
    padding-bottom: spacer(4);
  }

  .block-list-item__dashed {
    @include abs(#{var(--count-size)}, null, null, #{calc(var(--count-size) / 2)});

    transform: translate(-50%, 0);
    width: 2px;
    height: calc(var(--list-height) - var(--count-size));
    content: '';
    display: block;
    background: url('/assets/gfx/dashed-line-blue.svg') center top repeat-y;
  }

  &__count {
    @include size(#{var(--count-size)});
    border-radius: 50%;
    border: 2px solid;
    color: var(--bs-primary);
    display: inline-flex;
    align-items: center;
    flex: 0 0 var(--count-size);
    justify-content: center;
    overflow: hidden;

    &__text {
      transform: translate3d(0, -100%, 0);
      transition: transform 0.3s ease-in-out;

      .set--inview & {
        transform: translate3d(0, 0, 0);
      }
    }
  }

  // &:last-child {
  //   &::before {
  //     display: none;
  //   }
  // }
}
