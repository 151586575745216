// Bootstrap
$font-size-base: 16;

$font-family-sans-serif: 'PT Sans', sans-serif;
$font-family-serif: 'PT Sans', serif;
$font-family-handwriting: 'Ranga', cursive;

// Tip: Use goldenratio to generate font base sizes
// https://type-scale.com/
$font-sizes: (
  1: 64px,
  2: 40px,
  3: 32px,
  4: 28px,
  5: 24px,
  6: 20px,
  body-xxs: 10px,
  body-xs: 12px,
  body-sm: 14px,
  body-md: 16px,
  body-lg: 18px,
);

$display-font-sizes: (
  1: 96px,
  2: 48px,
  3: 32px,
  4: 24px,
);

$headings-font-family: $font-family-sans-serif;
$headings-font-weight: 700;
$headings-line-height: 1.1;
$headings-margin-bottom: 0.6em;

$display-font-family: $font-family-serif;
$display-font-style: italic;
$display-font-weight: 300;
$display-line-height: $headings-line-height;

$font-family-base: $font-family-sans-serif;
$font-weight-base: 500;
$line-height-base: 1.8;

$lead-font-size: map-get($font-sizes, body-lg);
$lead-font-weight: 600;

$text-muted: var(--bs-gray-600);

// Other
//? Put own extra variables here
