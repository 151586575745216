.content-block-countdown {
  background: url('/assets/gfx/light-blue-curly-images-bg.svg') center center no-repeat;
  background-size: contain;
  min-height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @include media-breakpoint-up(md) {
    min-height: 60vh;
  }

  &__title {
    margin-top: -#{spacer(5)};
  }

  &__list {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: spacer(2);
    background-color: var(--bs-light);

    @include media-breakpoint-up(md) {
      gap: spacer(4);
    }

    &__item {
      &.hide--seconds,
      &.hide--years,
      &.hide--days,
      &.hide--months {
        display: none;
      }
    }
  }

  &__end {
    background-color: var(--bs-light);
    color: var(--bs-primary);
    text-align: center;
    border-radius: $border-radius-xl;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: spacer(3) spacer(2);

    @include media-breakpoint-up(md) {
      outline: 20px solid var(--bs-light);
      padding: spacer(4) spacer(7);
    }

    &__title {
      position: relative;
      z-index: 10;

      @include media-breakpoint-up(md) {
        max-width: 70%;
      }
    }

    .counter__digit__divider {
      height: 0;
    }
  }
}

.counter {
  display: flex;
  flex-direction: column;
  text-align: center;

  &__digit {
    position: relative;
    font-weight: 700;
    font-style: normal;

    @include media-breakpoint-down(sm) {
      font-size: rem(36px);
    }

    &.flipping {
      .counter__digit__top::before {
        animation: flip 0.3s forwards;
      }
      .counter__digit__bottom::before {
        animation: flip-bottom 0.5s forwards;
      }
    }

    &__top,
    &__bottom {
      position: relative;
      background-color: var(--bs-secondary-300);
      padding: 0 spacer(3);
      width: 100%;
      height: 50%;
      overflow: hidden;

      @include media-breakpoint-up(md) {
        padding: 0 spacer(5);
      }

      &::before {
        @include abs(0, null, null, 0);
        width: 100%;
        height: calc(100% - 1px);
        transform: rotateX(0deg);
        transform-style: preserve-3d;
        content: attr(data-count);
        background-color: inherit;
      }
    }

    &__top {
      border-radius: $border-radius-xl $border-radius-xl 0 0;
      z-index: 2;

      &::before {
        transform-origin: center center;
      }
    }

    &__bottom {
      @include abs(50%, null, null, 0);
      line-height: 0;
      z-index: 4;

      &::before {
        transform-origin: top;
      }
    }

    &__divider {
      @include abs(50%, null, null, 0);
      width: 100%;
      height: 1px;
      background-color: var(--bs-light);
      transform: translateY(-50%);
      z-index: 5;

      @include media-breakpoint-up(md) {
        height: 2px;
      }

      &::before,
      &::after {
        @include size(10px);
        @include abs(50%, null, null, 0);

        @include media-breakpoint-up(md) {
          @include size(20px);
        }

        content: '';
        border-radius: 50%;
        display: block;
        background-color: var(--bs-light);
        transform: translate(-50%, -50%);
      }

      &::after {
        left: 100%;
      }
    }
  }

  &__label {
    padding: spacer(1) 0;
    background-color: var(--bs-secondary-200);
    border-radius: 0 0 $border-radius-xl $border-radius-xl;
    font-size: 0.7em;

    @include media-breakpoint-up(md) {
      font-size: 1em;
      padding: spacer(2) 0;
    }
  }
}

@keyframes flip {
  0% {
    transform: rotateX(0deg);
    z-index: 2;
  }
  0%,
  99% {
    opacity: 0.99;
  }
  100% {
    transform: rotateX(-90deg);
    opacity: 0;
  }
}

@keyframes flip-bottom {
  0%,
  50% {
    z-index: -1;
    transform: rotateX(90deg);
    opacity: 0;
  }
  51% {
    opacity: 0.99;
  }
  100% {
    opacity: 0.99;
    transform: rotateX(0deg);
    z-index: 5;
  }
}
