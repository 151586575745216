.register-form {
  fieldset:first-of-type {
    background-color: var(--bs-yellow-200);
    margin-left: calc(var(--card-padding) * -1 + var(--container-padding-x));
    margin-right: calc(var(--card-padding) * -1 + var(--container-padding-x));
    margin-top: calc(var(--card-padding) * -1);
    border-top-left-radius: $border-radius-xl;
    border-top-right-radius: $border-radius-xl;
    width: -webkit-fill-available;
    max-width: none;

    padding: 20px var(--card-padding);
    margin-bottom: 20px;
  }

  .form__fieldset__header {
    display: none;
  }
}

.reset-card-spacing {
  margin: calc(var(--card-padding) * -1);
  width: -webkit-fill-available;
}
