.embed-removed {
  background-color: var(--bs-background);
  text-align: center;
  padding: 2em 1em;
  margin: 2em 0;
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;

  .fstr-cookie-btn.button {
    background: var(--bs-secondary);

    &:hover {
      background: darkenColor(var(--bs-secondary), 0.2);
    }
  }

  .embed-removed__text {
    font-weight: 600;
    color: var(--bs-background-text-color);
    margin-bottom: 1em;
  }
}
