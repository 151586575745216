.faq-page {
  &__category {
    color: var(--bs-light);
    font-weight: bold;
    display: flex;
    align-items: center;
    gap: 1rem;
    border-radius: 8px 8px 0 0;
    background-color: var(--bs-primary-100);
  }
  &__list {
    border: solid 1px var(--bs-primary-100);
    padding: 1rem 1rem 0 1rem;
    &__item {
      border-top: 1px solid var(--bs-primary-200);
      margin-bottom: 1ex;
      padding-top: 2ex;
      &:first-child {
        border-top: 0px;
        margin-top: 0px;
        padding-top: 0px;
      }
    }
  }

  &__grid__column {
    gap: spacer(4);
  }
}
