// Reboot
//
// Normalization of HTML elements, manually forked from Normalize.css to remove
// styles targeting irrelevant browsers while applying new styles.
//
// Normalize is licensed MIT. https://github.com/necolas/normalize.css

// Document
//
// Change from `box-sizing: content-box` so that `width` is not affected by `padding` or `border`.

*,
*::before,
*::after {
  box-sizing: border-box;
}

// Root
//
// Ability to the value of the root font sizes, affecting the value of `rem`.
// null by default, thus nothing is generated.

:root {
  @if $font-size-root != null {
    @include font-size(var(--#{$prefix}root-font-size));
  }

  @if $enable-smooth-scroll {
    @media (prefers-reduced-motion: no-preference) {
      scroll-behavior: smooth;
    }
  }
}

// Body
//
// 1. Remove the margin in all browsers.
// 2. As a best practice, apply a default `background-color`.
// 3. Prevent adjustments of font size after orientation changes in iOS.
// 4. Change the default tap highlight to be completely transparent in iOS.

// scss-docs-start reboot-body-rules
body {
  margin: 0; // 1
  font-family: var(--#{$prefix}body-font-family);
  @include font-size(var(--#{$prefix}body-font-size));
  font-weight: var(--#{$prefix}body-font-weight);
  line-height: var(--#{$prefix}body-line-height);
  color: var(--#{$prefix}body-color);
  text-align: var(--#{$prefix}body-text-align);
  background-color: var(--#{$prefix}body-bg); // 2
  -webkit-text-size-adjust: 100%; // 3
  -webkit-tap-highlight-color: rgba($black, 0); // 4
  max-width: 100vw;
  width: 100%;
  overflow: hidden;
  overflow-y: auto;
}
// scss-docs-end reboot-body-rules

img {
  width: 100%;
  height: auto;
}

/**
 * Window.breakpoint
 * [1] For mobile
 * [2] For tablet
 * [3] For desktop
 * [4] For ultra desktop
 */
body::after {
  opacity: 0;
  position: absolute;
  pointer-events: none;
  font-size: 0px;

  @include media-breakpoint-between(xs, md) {
    content: 'sm';
  }

  @include media-breakpoint-only(md) {
    content: 'md';
  }

  @include media-breakpoint-only(lg) {
    content: 'lg';
  }

  @include media-breakpoint-only(xl) {
    content: 'xl';
  }

  @include media-breakpoint-up(xxl) {
    content: 'xxl';
  }
}
