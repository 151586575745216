body {
  --topbar-main-height: 100px;
  --topbar-upper-height: 51px;
  --topbar-bottom-height: 49px;
  --topbar-border-color: var(--bs-gray-300);

  @include media-breakpoint-down(md) {
    --topbar-main-height: 70px;
    --topbar-upper-height: 34px;
  }
}

.topbar {
  position: relative;
  height: calc(var(--topbar-main-height) + var(--topbar-upper-height));

  @include media-breakpoint-up(md) {
    margin-bottom: -#{$border-radius-xl};

    &.is--signed-in {
      margin-bottom: 0;
    }
  }

  &.is--signed-in {
    @include media-breakpoint-up(md) {
      height: calc(var(--topbar-main-height) + var(--topbar-upper-height) + var(--topbar-bottom-height));
    }

    .topbar__nav__metrics {
      @include media-breakpoint-down(md) {
        // display: none;
        position: fixed;
        top: 100vh;
        z-index: 1000;
        flex-direction: column;
        background: #fff;
        left: 50%;
        transform: translate(-50%, -90%) scale(0);
        opacity: 0;
        padding: spacer(2);
        border-radius: 1rem;
        width: calc(100% - spacer(3));
        align-items: center;
        pointer-events: none;
        transition: opacity 0.2s ease-in-out, transform 0.3s ease-in-out;

        .main-nav--active & {
          display: flex;
          flex-direction: column;
          opacity: 1;
          transform: translate(-50%, -100%) scale(0.8);
          transition: opacity 0.3s 0.6s ease-in-out, transform 0.3s 0.6s ease-in-out;
        }

        app-donut-chart {
          width: 100%;
        }
      }

      app-donut-chart {
        cursor: pointer;

        .donut-chart__figure__caption {
          transform: translate(-50%, -50%) scale(0.95);
        }
      }
    }
  }

  &__nav__metrics {
    display: flex;
    .set--fixed & {
      @include media-breakpoint-up(md) {
        display: none;
      }
    }
  }

  .cdk-global-scrollblock &,
  &.set--fixed {
    // --topbar-main-height: 70px;
  }

  .container {
    height: 100%;
    @include media-breakpoint-down(md) {
      padding: 0;
    }
  }

  &__upper {
    border-bottom: 1px solid var(--topbar-border-color);

    @include media-breakpoint-down(md) {
      background-color: var(--bs-primary-100);
    }

    .topbar__nav-list {
      justify-content: flex-end;
      gap: 0;
      font-size: map-get($font-sizes, 'body-md');

      &__item .button {
        border-right: 1px solid var(--topbar-border-color);
        padding-left: 1ex;
        padding-right: 1ex;

        @include media-breakpoint-down(md) {
          color: var(--bs-primary);
        }
      }

      &__item:first-child .button {
        border-left: 1px solid var(--topbar-border-color);
      }
    }
  }

  &__main {
    position: relative;
    border-bottom: 1px solid var(--topbar-border-color);
    height: var(--topbar-main-height);
    width: 100vw;

    z-index: 1000;
    background-color: var(--bs-light);

    // &::before {
    //   @include abs(0, null, null, 0);
    //   width: 100vh;
    //   height: 100vh;
    //   pointer-events: none;
    //   background-color: var(--bs-primary);
    //   border-radius: 50%;
    //   content: '';
    // }

    .cdk-global-scrollblock &,
    .set--fixed & {
      position: fixed;
      top: 0;
      animation: topbar-slide-in 0.3s ease-in-out both;
      height: 70px;

      .topbar__nav-list {
        @include get-font-size('body-lg');
      }
    }

    .main-nav--active & {
      @include media-breakpoint-down(md) {
        position: fixed;
        top: 0;
        background-color: var(--bs-primary);
        border-bottom: 0px;
      }
    }

    .hamburger {
      position: relative;
      z-index: 1000;

      @include media-breakpoint-down(md) {
        .main-nav--active & {
          color: var(--bs-light);
        }
      }
    }

    .container {
      display: flex;
      align-items: center;
    }

    .topbar__nav-list .button {
      @include media-breakpoint-up(md) {
        color: var(--bs-primary);

        &:hover {
          color: darkenColor(var(--bs-primary), 0.2);
        }
      }
    }
  }

  &__edition-logo {
    height: 90%;
    @include media-breakpoint-down(md) {
      order: 4;
      margin-left: 104px;

      .main-nav--active & {
        display: none;
      }
    }
    @include media-breakpoint-up(md) {
      margin-left: 0;
      margin-right: auto;
    }

    .meersen & {
      @include media-breakpoint-up(lg) {
        transform: translate(-56px, -12%);
        height: 110%;

        .set--fixed & {
        }
      }
    }

    img {
      height: 100%;
      object-fit: contain;

      @include media-breakpoint-up(md) {
        .set--fixed & {
          max-height: 70px;
        }
      }
    }
  }

  &__logo {
    position: relative;
    background-color: var(--bs-primary);
    color: var(--bs-light);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 880;

    @include media-breakpoint-down(md) {
      @include abs(0, null, null, 0);
      transition: all 0.4s ease-in-out;
      width: 100px;
      height: var(--topbar-main-height);
      order: 1;
      z-index: 1000;

      .main-nav--active & {
        @include abs(0, null, null, 50%);
        transform: translateX(-50%);
        transition: all 0.4s ease-in-out;
        width: 50%;
        height: 100px;
      }
    }

    @include media-breakpoint-up(md) {
      margin-left: 2ex;
      margin-top: $border-radius-xl;
      margin-bottom: -#{$border-radius-xl};
      width: 200px;
      border-radius: 0 0 $border-radius-xl $border-radius-xl;
      height: calc(var(--topbar-main-height) + #{$border-radius-xl});

      .set--fixed & {
        height: calc(var(--topbar-main-height) - #{$border-radius-xl});
      }
    }

    fstr-icon {
      width: 70%;
    }
  }

  &--bottom {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 800;
    width: 100%;
    background-color: var(--bs-light);
    border: 1px solid var(--topbar-border-color);
    border-width: 1px 0;

    .topbar__nav-list .button {
      color: var(--bs-primary);

      &:hover {
        color: darkenColor(var(--bs-primary), 0.2);
      }

      @include media-breakpoint-up(md) {
        &.is--current {
          color: var(--bs-primary);
        }
      }
    }
  }

  &__nav-list {
    @include get-font-size('body-lg');
    display: flex;
    gap: 3ex;
    align-items: center;

    .button {
      color: var(--bs-gray-600);

      &:hover {
        color: darkenColor(var(--bs-gray-600), 0.2);
      }
    }

    .is--current {
      font-weight: 700;

      @include media-breakpoint-down(md) {
        .button {
          color: var(--bs-secondary) !important;
        }
      }
    }

    &.main--nav {
      @include media-breakpoint-down(md) {
        position: fixed;
        top: calc(var(--topbar-main-height) / 2);
        left: 0;
        height: calc(100vh - var(--topbar-main-height));
        padding-top: 50px;
        width: 100%;
        z-index: 999;
        flex-direction: column;
        background-color: var(--bs-primary);
        pointer-events: none;
        // opacity: 0;
        transform: scale(0);
        transition: opacity 0.4s ease-in-out, transform 0.4s ease-in-out;
        transform-origin: top left;

        .set--fixed & {
          top: 0;
        }

        .main-nav--active & {
          transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
          top: var(--topbar-main-height);
          opacity: 1;
          pointer-events: all;
          transform: scale(1);
        }
      }
    }

    &__item {
      .main--nav & {
        @include media-breakpoint-down(md) {
          --list-item-delay: calc(0.3s + (var(--list-item-index, 0) * 0.1s));
          .button {
            color: var(--bs-primary-bg-subtle);
          }

          opacity: 0;
          transform: translate3d(0, 100%, 0);
          transition: opacity 0.2s, transform 0.5s, color 0.2s 0s;

          .main-nav--active & {
            opacity: 1;
            transform: translate3d(0, 0%, 0);
            transition: opacity 0.2s var(--list-item-delay), transform 0.5s var(--list-item-delay), color 0.2s 0s;
          }
        }
      }
    }
  }
}

@keyframes topbar-slide-in {
  from {
    transform: translate3d(0, -100%, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
