$image-resizing-anchorpoints: (
  'topleft': top left,
  'topcenter': top center,
  'topright': top right,
  'middleleft': center left,
  'middlecenter': center center,
  'middleright': center right,
  'bottomleft': bottom left,
  'bottomcenter': bottom center,
  'bottomright': bottom right,
);

@each $name, $cssvariant in $image-resizing-anchorpoints {
  [data-object-position='#{$name}'],
  .object-position--#{$name} {
    object-position: $cssvariant;
  }

  [data-mask-position='#{$name}'],
  .mask-position--#{$name} {
    mask-position: $cssvariant;
  }
}
