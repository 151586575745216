.block-payoff {
  background-color: var(--bs-tertiary);
  overflow: hidden;
  margin-top: 1rem;
  &__logo {
    max-width: 500px;
    height: 128px;
  }
  &__socials {
    a {
      color: unset;
    }
    color: var(--bs-light);
    fill: var(--bs-light);
  }
  &__main {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
}
.payoff-gallery {
  display: flex;
  flex-direction: column;
  justify-content: center;
  @include media-breakpoint-down(md) {
    flex-direction: row;
  }
  &__item {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    fstr-image {
      background-color: var(--bs-light);
      padding: 1rem;
      @include media-breakpoint-down(md) {
        padding: 0.8rem;
      }
      img {
        object-fit: cover;
        display: block;
        width: 100%;
      }
    }
  }
  &.is--left {
    align-items: end;
    .payoff-gallery__item {
      &:nth-child(1) {
        transform: rotate(-26deg);
        max-width: 215px;
        img {
          aspect-ratio: 1/1;
        }
      }
      &:nth-child(2) {
        max-width: 310px;
        transform: rotate(7deg);
        img {
          aspect-ratio: 310/215;
        }
      }
      &:nth-child(3) {
        max-width: 230px;
        transform: translateY(-20px) rotate(-10deg);
        img {
          aspect-ratio: 230/215;
        }
      }
    }
  }
  &.is--right {
    align-items: start;
    .payoff-gallery__item {
      &:nth-child(1) {
        max-width: 310px;
        transform: rotate(-7deg);
        img {
          aspect-ratio: 230/215;
        }
      }
      &:nth-child(2) {
        transform: rotate(5deg);
        max-width: 180px;
        align-self: center;
        img {
          aspect-ratio: 180/215;
        }
      }
      &:nth-child(3) {
        transform: rotate(-14deg) translateY(-22px);
        max-width: 180px;
        img {
          aspect-ratio: 180/215;
        }
      }
    }
  }
}
