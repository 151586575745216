.confirm {
  text-align: center;
  background-color: var(--bs-light);

  &__inner {
  }

  .button {
    min-width: 90px;
  }
}

.confirm__message {
  color: var(--bs-dark);
}
.confirm-dialog {
  overflow: hidden;

  .mat-dialog-container {
    box-shadow: none;
    padding-top: 0;
    padding-right: 0;
    padding-left: 0;
  }
}
.confirm-dialog .mat-dialog__header {
  background-color: var(--bs-secondary);
  color: var(--bs-light);
  margin: -24px -24px 0 -24px;
}

.theme-light .confirm-dialog .mat-dialog-container,
.theme-dark .confirm-dialog .mat-dialog-container,
.confirm-dialog .mat-dialog-container {
  background: none;
}
