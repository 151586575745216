.assignment {
  .assignment--header-date {
    font-size: 0.9rem;
    transform: translateY(1px);
  }

  &__main {
    width: 100%;
    // overflow: hidden;
    // padding-top: 40px;

    @include media-breakpoint-up(lg) {
      margin-top: -100px;

      .content-block-list__item:first-child {
        position: relative;
        margin-bottom: 150px;

        &::before {
          @include abs(0, null, null, 50%);
          width: calc(#{map-get($container-max-widths, 'xxl')} + #{spacer(8)});
          background-color: var(--bs-light);
          content: '';
          height: 50px;
          border-radius: $border-radius-xl $border-radius-xl 0 0;
          transform: translate(-50%, -100%);
        }
      }
    }
  }
}
