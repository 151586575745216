.app {
  position: relative;
}

.app__router {
  position: relative;
  min-height: calc(var(--vh, 1vh) * 100);
}

.app__inner {
  min-height: calc(var(--vh, 1vh) * 100);
  position: relative;
}
