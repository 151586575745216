@keyframes loader {
  0% {
    transform: translateX(0%) scaleX(0);
  }
  50% {
    transform: translateX(33%) scaleX(33%);
  }
  100% {
    transform: translateX(100%) scaleX(0%);
  }
}

.loading {
  position: fixed;
  top: 0;
  height: 5px;
  background: #dddddd;
  left: 0;
  right: 0;
  z-index: 9999999;
  display: flex;

  &__progression {
    background: var(--bs-primary);

    &.type--flux {
      width: 100vw;
      animation: loader 2s infinite ease;
      transform-origin: left;
    }
  }
}