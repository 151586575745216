app-avatar {
  display: contents;
}

body {
  --avatar-bg-color: #{var(--bs-tertiary)};
  --avatar-text-color: #{var(--bs-light)};
}

.avatar {
  border-radius: 50%;
  overflow: hidden;
  min-width: 20px;
  width: 100%;
  color: var(--avatar-text-color);
  background-color: var(--avatar-bg-color);
  border: solid 4px var(--bs-light);
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 1/1;

  fstr-image {
    display: contents;
  }

  &.has--image {
    background-color: white;
  }

  &--xs {
    width: 30px;
    min-width: 30px;
  }

  &--sm {
    width: 50px;
    min-width: 50px;
  }

  &--md {
    width: 88px;
    min-width: 88px;
  }
  &--lg {
    min-width: 130px;
    width: 130px;
  }

  img {
    object-fit: cover;
    aspect-ratio: 1/1;
  }

  &__name {
    width: 50%;
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    fstr-icon {
      width: 100%;
      height: 100%;
    }
  }

  svg {
    width: 100%;
    height: 100%;

    text {
      font-weight: 900;
      font-size: 130px; // gets resized with svg, so its not the real fontsize but rather the ratio relative to the svg
      font-family: var(--bs-body-font-family);
      fill: white;
      text-anchor: middle;
      dominant-baseline: middle;
    }
  }
}
