.markdown-content {
  @include get-font-size('body-lg');

  @include media-breakpoint-down(md) {
    @include get-font-size('body-md');
  }

  $markdown-font-sizes: (
    h1: map-get($font-sizes, 2),
    h2: map-get($font-sizes, 3),
    h3: map-get($font-sizes, 4),
    h4: map-get($font-sizes, 5),
    h5: map-get($font-sizes, 6),
    h6: map-get($font-sizes, 'body-lg'),
  );

  @import './blockquote';
  @import './image';
  @import './table';
  @import './type';
  @import './media';

  *:last-child {
    margin-bottom: 0;
  }
}

@import './simple-markdown';
@import './embed-removed';
