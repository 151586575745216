.mdc-snackbar {
  --mdc-snackbar-container-shape: #{$border-radius-xl} !important;
  --mdc-snackbar-container-color: var(--bs-primary-900);
  --mdc-snackbar-supporting-text-weight: 700 !important;
  --mat-snack-bar-button-color: var(--bs-light) !important;

  border-radius: 30px;
  font-family: $font-family-base;
  // color: var(--color-snackbar);

  &.bg-success {
    --mdc-snackbar-container-color: var(--bs-success) !important;

    --color-snackbar: var(--bs-light) !important;
    .mat-simple-snackbar-action {
      // color: var(--color-snackbar);
    }
  }

  .mat-simple-snackbar {
    font-family: $font-family-base;
  }

  .mat-simple-snack-bar-content {
    font-weight: 500;
  }
}
