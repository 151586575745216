// Bootstrap
$colors: (
  blue-100: #eef9ff,
  blue-200: #ddeffa,
  blue-900: #003048,
  blue: #006599,
  indigo: #6610f2,
  purple: #6f42c1,
  pink-100: #fff5fc,
  pink-200: #ffd9f1,
  pink: #d3138d,
  red: #dc3545,
  orange: #fd7e14,
  yellow-200: #fff3d1,
  yellow-300: #ffe79d,
  yellow: #fcc627,
  green: #52af32,
  teal: #20c997,
  cyan: #17a2b8,
);

$theme-colors: (
  primary: color(blue),
  primary-100: color(blue-100),
  primary-200: color(blue-200),
  primary-900: color(blue-900),
  secondary: color(yellow),
  secondary-200: color(yellow-200),
  secondary-300: color(yellow-300),
  tertiary: color(pink),
  tertiary-100: color(pink-100),
  tertiary-200: color(pink-200),
  success: color(green),
  info: color(blue),
  warning: color(orange),
  danger: color(red),
  light: white,
  dark: black,
  muted: #adb5bd,
);

$grays: (
  900: #212529,
  800: #343a40,
  700: #495057,
  600: #6c757d,
  500: #adb5bd,
  400: #ced4da,
  300: #dee2e6,
  200: #f3f3f3,
  100: #f8f9fa,
);

//
$primary-bg-subtle: #e7f6fd; //tint-color(theme-color(primary), 90%)
$body-secondary-color: #757575;

// Other
//? Put own extra variables here
$primary-gradient: linear-gradient(89.65deg, theme-color(primary) 0.25%, theme-color(primary-600) 100%);
