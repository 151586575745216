.number-list {
  --bs-gutter-y: #{spacer(3)};
  align-items: center;
  justify-content: center;

  &__item {
    // Pretty colors always
    &:nth-child(6n + 1) {
      color: var(--bs-primary);
    }
    &:nth-child(6n + 2) {
      color: var(--bs-secondary);
    }
    &:nth-child(6n + 3) {
      color: var(--bs-tertiary);
    }
    &:nth-child(6n + 4) {
      color: var(--bs-tertiary);
    }
    &:nth-child(6n + 5) {
      color: var(--bs-primary);
    }
    &:nth-child(6n + 6) {
      color: var(--bs-secondary);
    }
  }
}
