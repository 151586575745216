:root {
  @if $font-size-root != null {
    @include font-size(var(--bs-root-font-size));
  }

  @if $enable-smooth-scroll {
    @media (prefers-reduced-motion: no-preference) {
      scroll-behavior: smooth;
    }
  }
}

%heading {
  margin-top: 0; // 1
  margin-bottom: $headings-margin-bottom;
  font-family: $headings-font-family;
  font-style: $headings-font-style;
  font-weight: $headings-font-weight;
  line-height: $headings-line-height;
  color: var(--bs-heading-color);
  word-break: break-word;
  text-wrap: balance;
}

h1,
.h1 {
  @extend %heading;
  @include get-font-size(1);
}

h2,
.h2 {
  @extend %heading;
  @include get-font-size(2);
}

h3,
.h3 {
  @extend %heading;
  @include get-font-size(3);
}

h4,
.h4 {
  @extend %heading;
  @include get-font-size(4);
}

h5,
.h5 {
  @extend %heading;
  @include get-font-size(5);
}

h6,
.h6 {
  @extend %heading;
  @include get-font-size(6);
}

p {
  @include get-font-size('body-lg');
  margin-top: 0;
  margin-bottom: $paragraph-margin-bottom;
  //text-wrap: balance;

  @include media-breakpoint-down(md) {
    @include get-font-size('body-md');
  }
}

.lead {
  @include font-size($lead-font-size);
  font-weight: $lead-font-weight;
}

// Type display classes
@each $display, $font-size in $display-font-sizes {
  .display-#{$display} {
    @include font-size($font-size);
    font-family: $display-font-family;
    font-style: $display-font-style;
    font-weight: $display-font-weight;
    line-height: $display-line-height;
  }
}

.link {
  color: var(--bs-primary);
  text-decoration: $link-decoration;

  &:hover {
    color: darkenColor(var(--bs-primary), 0.2);
    text-decoration: $link-hover-decoration;
  }
}

strong {
  font-weight: bold;
}

@each $color, $value in $theme-colors {
  .text-bg-#{$color} {
    color: white;
    background-color: RGBA(var(--bs-#{$color}-rgb), var(--bs-bg-opacity, 1));

    @if (contrast-ratio($value) < 2) {
      color: black;
    }
  }
}

@each $name, $size in $display-font-sizes {
  .fs-display-#{$name} {
    @include font-size($size);
  }
}
