.file-upload {
  background: var(--bs-light);
  border: 1px solid var(--bs-gray-200);
  border-width: 1px 0;
  border-radius: $border-radius;
  transition: all 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: spacer(2) spacer(3);
  gap: spacer(2);

  .file-name-preview-label {
    word-break: break-all;
  }

  &.is--drag-over {
    background: var(--bs-primary-300);
  }
  &.is--not-empty {
    // background: var(--bs-primary-200);
    justify-content: flex-start;
  }

  .custom-field--invalid & {
    border-color: var(--mat-warn-color, #b71721);
    background-color: lighten(theme-color(danger), 55%);
  }

  &__file {
    display: flex;
    align-items: center;
    width: 100%;

    @include media-breakpoint-down(md) {
      display: block;
      width: 100%;
      text-align: center;

      &__actions {
        justify-content: center;
      }
    }

    &__image {
      border: 1px solid var(--bs-primary-400);
      border-radius: 5px;
      background: white;
      overflow: hidden;

      @include media-breakpoint-up(md) {
        width: 25%;
        max-width: 80px;
      }

      img {
        max-width: 180px;
        max-height: 150px;
        margin: auto;
        object-fit: contain;
      }
    }

    &__meta {
      .text-truncate {
        max-width: 300px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      @include media-breakpoint-up(md) {
        max-width: 77%;
      }
    }

    .button.button-link {
      height: auto;
      overflow: visible;
    }
  }

  a {
    cursor: pointer;
  }
}

.truncate-full-width {
  display: flex;
  align-items: center;

  &__content {
    flex: 1;
    min-width: 0;

    > * {
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
