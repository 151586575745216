.has--skew-bg {
  position: relative;
  z-index: 1;

  &:nth-child(odd) {
    &::before {
      background-image: url('/assets/gfx/halftone-white.png');
      --skew-bg-color: var(--bs-secondary);
    }
  }
  &:nth-child(even) {
    &::before {
      background-image: url('/assets/gfx/halftone-dark.png');
      --skew-bg-color: var(--bs-primary);
    }
  }

  &::before {
    content: '';
    width: 80%;
    height: 250px;
    position: absolute;
    z-index: -1;
    right: 5px;
    top: -10px;
    transform: rotate(4deg);
    background-color: var(--skew-bg-color);

    @include media-breakpoint-down(md) {
      height: 100px;
      transform: rotate(8deg);
    }
  }
}
