.content-block-list {
  position: relative;

  &__header {
    margin-bottom: spacer(4);
    @include media-breakpoint-down(md) {
      text-align: center;
      margin-bottom: 0;
    }
  }

  &__title {
    position: relative;
    text-wrap: inherit;
    color: var(--bs-primary);

    @include media-breakpoint-up(md) {
      position: sticky;
      top: 100px;
      margin-bottom: 0;
    }
    @include media-breakpoint-down(md) {
      align-items: center;
    }
  }

  .block-list__item:last-child {
    .block-list-item {
      padding-bottom: 0;
      min-height: 0;
    }
    .block-list-item__dashed {
      display: none;
    }
  }

  .block-list {
    display: flex;
    flex-direction: column;
  }
}
