.results-header {
  text-align: center;
  background-color: var(--bs-primary-100);
  &__main {
    position: relative;
    z-index: 0;
    &::before {
      @include size(100%, 80%);
      background: url('/assets/gfx/white-curly-images-bg.svg') center no-repeat;
      background-size: contain;
      left: 0;
      top: 10%;
      content: '';
      position: absolute;
      pointer-events: none;
      z-index: -1;
    }
  }
  fstr-image {
    img {
      @extend .rounded-tl;
      @extend .rounded-br;
    }
  }
  .logo-100 {
    width: 525px;
    height: 135px;
    max-width: 100%;

    svg {
      fill: var(--bs-primary);
      padding: 2rem 2rem 1rem 2rem;
    }
  }
}
