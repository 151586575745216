.simple-markdown {
  p:last-child {
    margin-bottom: 0;
  }
  p {
    margin-bottom: 1rem;
  }
  blockquote {
    &::before {
      font-size: 1rem;
    }
    display: block;
    font-style: italic;
  }
}
