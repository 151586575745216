.title-squiggle {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;

  &.squiggle--center {
    align-items: center;
  }

  fstr-icon {
    font-size: 0.9em;
  }
}
