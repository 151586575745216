.auth-layout {
  min-height: calc(var(--vh, 1vh) * 100);
  position: relative;
  z-index: 2;

  &__main {
  }
  background: url('/assets/gfx/lightblue-curly-images-bg.svg') center left no-repeat;
  @include media-breakpoint-down(md) {
    background: url('/assets/gfx/lightblue-curly-images-bg.svg') center top no-repeat;
    background-size: contain;
    background-position: 0px 50px;
  }
  &__inner {
    max-width: 550px;
    margin: 0 auto;
  }

  &__logo {
    max-width: 455px;
    height: 70px;
  }
}
