.fr-img-space-wrap {
  margin-top: rem(20px);
  margin-bottom: map-get($spacers, 4);
}

.fr-inner {
  color: $text-muted;
  margin: 5px 0 0;
  display: inline-block;
  font-size: 14px;
}

img {
  max-width: 100%;

  // @supports (width: fill-available) or (width: -webkit-fill-available) {
  //   @include media-breakpoint-up(lg) {
  //     --outset: -100px;
  //     max-width: max-content;
  //     margin-left: var(--outset);
  //     margin-right: var(--outset);
  //     width: -webkit-fill-available;
  //   }
  //   @include media-breakpoint-up(xxl) {
  //     --outset: -150px;
  //   }
  // }
}
