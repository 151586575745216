.has--bg-graphic {
  background-image: url('/assets/gfx/icon-squiggle-white.svg');
  background-repeat: no-repeat;
  background-position: center;
  height: 100%;

  &:nth-child(1n) {
    background-color: var(--bs-primary);
  }
  &:nth-child(2n) {
    background-color: var(--bs-secondary);
  }
  &:nth-child(3n) {
    background-color: var(--bs-tertiary);
  }
}
