.block-default {
  background-color: var(--bs-yellow-200);
  border-radius: $border-radius-xl;
  --bs-gutter-x: 2rem;

  &__figure {
    display: flex;
    align-items: center;
    margin-top: 1.5rem;

    fstr-image > img {
      border-radius: $border-radius-xl;
    }
  }
}
