.content-block-list-icon {
  position: relative;
  margin-bottom: 5rem;

  &__list {
    justify-content: space-between;
  }

  &__card {
    .markdown-content {
      flex: 1;

      @include media-breakpoint-up(xl) {
        max-width: 290px;
      }
    }

    @include media-breakpoint-down(md) {
      .chip--lg {
        --chip-size: 2.4rem;
        transform: translateY(5px);
      }
    }

    @include media-breakpoint-down(lg) {
      .chip--lg {
        --chip-size: 2.6rem;
        // transform: translateY(5px);
      }
    }
  }
}
