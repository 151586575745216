.donut-chart {
  &__figure {
    position: relative;
    pointer-events: none;
    &__caption {
      @include abs(50%, null, null, 52%);
      transform: translate(-50%, -50%);
    }
  }
}
