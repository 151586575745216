.page-header {
  overflow: hidden;

  &__image {
    position: relative;

    @include media-breakpoint-down(md) {
      --aspect-ratio: 16/9;
    }
    img {
      border-radius: $border-radius-xl;
      overflow: hidden;
      max-height: 500px;
      width: 100%;
      object-fit: cover;
      background-color: var(--bs-gray-200);
      aspect-ratio: var(--aspect-ratio);
      position: relative;
      z-index: 3;
    }

    &:not(.set--gallery) {
      --offset: 15%;

      &::before {
        @include size(calc(100% + (var(--offset) * 2)));

        background: url('/assets/gfx/lightblue-curly-images-bg.svg') center top no-repeat;
        background-size: contain;
        content: '';
        position: absolute;
        transform: translate(20%, calc(var(--offset) * -1));
        z-index: -1;
      }
    }
  }

  .page-header__text {
    @include get-font-size(6);

    max-width: 560px;

    @include media-breakpoint-down(md) {
      @include get-font-size('body-lg');
    }
  }

  &__subtitle {
    @include get-font-size('body-lg');

    @include media-breakpoint-down(md) {
      @include get-font-size('body-md');
    }
  }
}

.set--gallery {
  &.page-header__image img {
    border-radius: unset;
  }
  .page-header__gallery {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    // grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 10px;

    fstr-image,
    fstr-youtube-player {
      height: fit-content;
      display: block;

      img {
        display: block !important;
      }
    }

    &__item {
      overflow: hidden;

      @include media-breakpoint-down(md) {
        display: none;
      }
      img {
        aspect-ratio: 1/1;
      }

      &.has--bg-graphic {
        aspect-ratio: 1/1;
      }

      &:nth-child(1) {
        grid-area: 1 / 1 / 2 / 4;
        border-radius: $border-radius-xl;

        @include media-breakpoint-down(md) {
          display: block;
        }

        img {
          max-height: 340px;
          display: block;
        }
      }
      &:nth-child(2) {
        grid-area: 2 / 1 / 3 / 2;

        @extend .rounded-tr;
        @extend .rounded-bl;
        background-color: var(--bs-tertiary);
      }
      &:nth-child(3) {
        grid-area: 2 / 2 / 3 / 3;

        @extend .rounded-circle;
        background-color: var(--bs-secondary);
      }
      &:nth-child(4) {
        grid-area: 2 / 3 / 3 / 4;

        @extend .rounded-tl;
        @extend .rounded-br;
        background-color: var(--bs-primary);
      }
    }
  }
}
