@each $name, $colors in $button-styles {
  $font-color: nth($colors, 1);
  $bg-color: nth($colors, 2);

  .button.button--#{$name} {
    --button-background: #{$bg-color};
    --button-background-darker: #{darkenColor($bg-color, 0.2)};
    --button-background-alpha: #{alpha($bg-color, 0.2)};
    --button-color: #{$font-color};
  }
  .button-chip.button--#{$name} {
    fstr-icon {
      @extend .chip--#{$name};
    }
  }
}

.button.button--primary-gradient {
  @extend .button--primary;
  //background: $primary-gradient;
  background: linear-gradient(83deg, #ff6300 0%, #ff3d00 25%, #ff6300 50%, #ff3d00 100%), #d9d9d9;
  background-size: 400% 400%;
  transition: background-position 0.5s ease, box-shadow 0.5s;
  box-shadow: 0px 0px 0px 0px rgba(#ff3d00, 0);

  &.has--dot span::before {
    transition: all 0.2s;
  }

  @media (hover: hover) {
    &:hover {
      background-position-x: 100%;
      box-shadow: 0px 2px 29px 0px rgba(#ff3d00, 0.45);

      &.has--dot span::before {
        transform: scale(1.4);
      }
    }
  }
}
