.content-block-participants {
  background-color: var(--bs-tertiary-100);
  border-radius: $border-radius-xl;

  &__images {
    background: url('/assets/gfx/white-curly-images-bg.svg') center center no-repeat;
    background-size: contain;

    @include media-breakpoint-down(md) {
      padding: 30px 0;
    }
    @include media-breakpoint-up(md) {
      margin-top: -40px;
      margin-bottom: -40px;
      display: flex;
    }
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto auto;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    gap: 10px;
  }

  &__list__item {
    &:nth-child(1) {
      grid-area: 1 / 1 / 2 / 2;
      align-self: flex-end;
    }
    &:nth-child(2) {
      grid-area: 1 / 2 / 3 / 3;
      align-self: center;
    }
    &:nth-child(3) {
      grid-area: 2 / 1 / 3 / 2;
      align-self: flex-start;
    }

    img {
      border-radius: $border-radius-xl;
      display: block;
      max-height: 300px;
      object-fit: cover;
    }
  }
}
