.form-radio-buttons {
  .mat-mdc-radio-group {
    display: flex;
    justify-content: stretch;
    align-items: center;
  }

  .mat-form-field {
    --post-primary-color: var(--bs-primary-900);
    width: 25%;

    &.type--2 {
      --post-primary-color: var(--bs-secondary);
    }

    &.type--3 {
      --post-primary-color: var(--bs-tertiary);
    }

    &.type--1 {
      --post-primary-color: var(--bs-primary);
    }

    .mdc-form-field {
      color: var(--post-primary-color);
    }

    &:first-of-type {
      .mdc-form-field {
        border-radius: $border-radius-xl 0 0 0;
      }
    }
    &:last-of-type {
      .mdc-form-field {
        border-radius: 0 $border-radius-xl 0 0;
      }
    }
  }

  .mat-mdc-radio-checked {
    .mdc-form-field {
      background-color: var(--bs-light);
    }
  }

  .mdc-form-field {
    position: relative;
    width: 100%;
    background-color: var(--bs-gray-200);
    display: flex;
    align-items: center;
    justify-content: center;

    & .mdc-label {
      margin-left: auto;
      margin-right: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: spacer(3) spacer(2);
      gap: spacer(1);
      cursor: pointer;
      font-weight: 700;

      @include media-breakpoint-down(md) {
        flex-direction: column;
        padding: spacer(2) spacer(2);
      }

      .label {
        @include media-breakpoint-down(md) {
          font-size: 11px;
        }
      }
    }

    .mdc-radio {
      opacity: 0;
      position: absolute;
      pointer-events: none;
    }
  }
}
