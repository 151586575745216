.assignment-list-card {
  margin-top: 20px;
  &__main {
    background-color: var(--bs-primary-100);
  }
  &__title {
    color: var(--bs-tertiary);
    text-decoration: none;
  }
  &__subtitle {
    color: var(--bs-primary);
  }

  &__intro {
    color: var(--bs-primary);
  }
  &__status {
    color: var(--bs-green);
    display: flex;
    align-items: center;
    font-weight: bold;
    line-height: 1.3em;
    gap: 7px;

    @include media-breakpoint-down(md) {
      font-size: 14px;
    }
  }

  &__actions {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 1rem;

    @include media-breakpoint-down(md) {
      flex-direction: column;
      align-items: center;
      margin-top: 15px;
    }
  }
  fstr-image {
    height: fit-content;
    display: block;

    img {
      display: block !important;
    }
  }

  &.set--active {
    .assignment-list-card {
      &__main {
        background-color: var(--bs-tertiary);
      }
      &__title,
      &__status,
      &__intro {
        color: var(--bs-light);
      }
      &__subtitle {
        color: var(--bs-secondary);
        display: block;
        line-height: 1;
        padding-bottom: 1rem;
      }
    }
  }
}
