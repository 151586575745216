.content-block-faq {
  &__title {
    position: relative;
  }

  &__main {
    @include media-breakpoint-up(md) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: $grid-gutter-width;
    }
  }

  &__list__item {
    margin-bottom: 1rem;
    border-bottom: 2px solid var(--bs-gray-200);

    &:last-child {
      border-bottom: 0;
    }
  }
}
