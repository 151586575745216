.file-upload--avatar {
  background: transparent;
  border-color: transparent;
  margin-left: -10px;
  margin-right: -10px;

  @include media-breakpoint-down(md) {
    flex-flow: column;
    text-align: center;
    gap: 20px;
  }

  &.is--drag-over {
    border: 2px dashed var(--bs-secondary);
    background: var(--bs-secondary-300);
  }
  &.is--not-empty {
    background: transparent;
    border-color: transparent;
  }
}
