.card {
  --card-padding: 2rem;
  background-color: var(--bs-light);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
  border-radius: $border-radius-xl;
  padding: var(--card-padding);
  width: 100%;

  @include media-breakpoint-down(md) {
    --card-padding: 1.2rem;
  }
}
