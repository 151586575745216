.hamburger {
  $timing: 0.2s;
  $easing: cubic-bezier(0.23, 1, 0.32, 1);

  --hamburger-width: 1.5em;
  --hamburger-height: 2px;
  --hamburger-spacing: 0.5em;

  // @include media-breakpoint-up(md) {
  //   --hamburger-width: 25px;
  //   --hamburger-height: 2px;
  //   --hamburger-spacing: 8px;
  // }

  // @include media-breakpoint-up(xl) {
  //   --hamburger-width: 35px;
  //   --hamburger-height: 3px;
  //   --hamburger-spacing: 10px;
  // }

  // width: 44px;
  height: calc(var(--hamburger-spacing) * 2 + var(--hamburger-height));
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--bs-primary);

  .main-nav--active & {
    color: var(--bs-primary-overlay);
  }

  &__lines {
    position: relative;
    background: currentColor;

    &,
    &::before,
    &::after {
      width: var(--hamburger-width);
      height: var(--hamburger-height);
      display: inline-block;
      content: '';
      background: currentColor;
    }

    &::before {
      @include abs(auto, 0, var(--hamburger-spacing), 0);

      transition: background $timing, bottom $timing $timing $easing, transform $timing $easing;
    }

    &::after {
      @include abs(var(--hamburger-spacing), 0, auto, 0);

      transition: background $timing, top $timing $timing $easing, transform $timing $easing;
    }
  }

  &.is--active {
    .hamburger__lines {
      //color: var(--bs-primary);
      // stylelint-disable declaration-no-important
      background: transparent !important;

      &::before {
        bottom: 0;
        transition: background $timing, bottom $timing $easing, transform $timing $timing $easing;
        transform: rotate(45deg);
      }

      &::after {
        top: 0;
        transition: background $timing, top $timing $easing, transform $timing $timing $easing;
        transform: rotate(-45deg);
      }
    }
  }
}
