@mixin abs($top: 0, $right: $top, $bottom: $top, $left: $top) {
  position:absolute;
  top:$top; right:$right; bottom:$bottom; left:$left;
}

@mixin fxd($top:0, $right: $top, $bottom: $top, $left: $top) {
  position:fixed;
  top:$top; right:$right; bottom:$bottom; left:$left;
}

@mixin rel($top: 0, $right: $top, $bottom: $top, $left: $top) {
  position: relative;
  top:$top; right:$right; bottom:$bottom; left:$left;
}
