.post-form {
  padding: 0;

  .form {
    --bs-gutter-y: 0;
  }

  .file-upload {
    width: calc(100% - #{spacer(4)});
    border-width: 1px;
    margin: 0 auto;
    background-color: var(--bs-gray-100);
  }

  .custom-field.type--wysiwy {
    .custom-field__error-wrapper {
      padding: spacer(2) spacer(3);
      font-size: rem(14);
    }

    label {
      display: none;
    }
    quill-editor {
      width: 100%;
      display: flex;
      flex-direction: column;
    }
    .ql-container {
      min-height: 100px;
      border-color: var(--bs-light);
      font-family: inherit;
      // background-color: var(--bs-gray-100);
    }

    .ql-toolbar.ql-snow {
      border-color: var(--bs-gray-200);
      border-width: 0 0 1px 0;
      margin-top: -5px;
      display: inline-flex;
      justify-content: flex-start;

      // background-color: var(--bs-gray-100);
    }
  }

  &__submit__type--label {
    text-transform: lowercase;
    padding-left: 1ex;
  }

  &__submit {
    background-color: var(--bs-primary-900);

    &.type--3 {
      background-color: var(--bs-tertiary);
    }
    &.type--1 {
      background-color: var(--bs-primary);
    }
    &.type--2 {
      background-color: var(--bs-secondary);
    }
  }
}
