.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.text-decoration-none {
  &:hover,
  &:focus {
    text-decoration: none;
  }
}

.font-display {
  font-family: $display-font-family;
  font-style: italic;
  font-weight: bold;
}
.text-primary-gradient {
  background: $primary-gradient;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding-right: 0.2em;
  display: inline-block; // keep words together if it needs to go to a new line
}

.link-unset,
.block-link {
  text-decoration: none;
  color: currentColor;

  &:focus-visible {
    outline: 2px solid var(--bs-warning);
    outline-offset: 3px;
  }
}

.text-balance {
  text-wrap: balance;
}
