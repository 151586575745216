@include media-breakpoint-down(md) {
  .header-gallery {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    grid: none;
  }
  .header__meta,
  .header__images {
    order: -1;
  }
  .header__images {
    margin-inline: -$container-padding-x;
  }
  .header__main {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    text-align: center;
  }
  .header-gallery__item {
    img {
      object-fit: cover;
      height: 100%;
    }
    &:nth-child(1) {
      grid-area: 1 / 1 / 3 / 2;
      img {
        border-radius: 0 1rem 1rem 0;
      }
    }
    &:nth-child(2) {
      grid-area: 1 / 2 / 2 / 4;
      img {
        border-radius: 0 1rem 0rem 1rem;
      }
    }
    &:nth-child(3) {
      grid-area: 2 / 2 / 3 / 3;
      img {
        border-radius: 1rem;
      }
    }
    &:nth-child(4) {
      grid-area: 2 / 3 / 3 / 4;
      img {
        border-radius: 1rem 0 1rem 0;
      }
    }
    &:nth-child(5) {
      display: none;
    }
    &:nth-child(6) {
      display: none;
    }
  }
}

@include media-breakpoint-up(md) {
  .header {
    --header-spacer-height: 50vmax;
    // --margin-inline-x: 10px;
    overflow: hidden;
    position: relative;
    max-width: 100vw;

    &::before {
      @include size(100%);

      background: url('/assets/gfx/lightblue-curly-images-bg.svg') center top no-repeat;
      background-size: 80% auto;
      content: '';
      position: absolute;
      bottom: 0;
      pointer-events: none;
      top: 25%;
    }

    &__main {
      text-align: center;
    }

    &__inner {
      position: relative;
    }

    &__images {
      height: var(--header-spacer-height);
      position: relative;
    }
  }

  .header-gallery {
    position: relative;

    &__item {
      --side-offset-max: calc((100vw - var(--container-max-width)) / 2);
      @include abs(0, auto, auto, auto);
      display: inline-block;
      pointer-events: none;
      max-width: 50%;

      fstr-image {
        aspect-ratio: 1/1;
        display: inline-flex;
        align-items: center;
      }

      img {
        pointer-events: auto;
        width: 100%;
        object-fit: contain;
        display: block !important;
        border: 5px solid var(--bs-light);

        max-height: 300px;
        max-width: 500px;
      }

      // for testing
      //&::after {
      //  content: attr(data-index);
      //  position: absolute;
      //  z-index: 10;
      //}

      &:nth-child(1) {
        left: 0;
        transform: translate(-120%, -100%);
        max-width: var(--side-offset-max);
        min-width: 330px;

        img {
          border-radius: 0 2.5rem 2.5rem 2.5rem;
          max-height: 320px;
        }
      }
      &:nth-child(2) {
        right: 0;
        transform: translate(120%, calc(-100% - 150px));
        max-width: var(--side-offset-max);
        min-width: 330px;

        img {
          border-radius: 2.5rem 2.5rem 0rem 2.5rem;
        }
      }
      &:nth-child(3) {
        right: 0;
        transform: translate(10%, 30%);
        max-width: calc(50% + 60px);

        img {
          border-radius: 2.5rem 0 2.5rem 0;
        }
      }
      &:nth-child(4) {
        left: 0;
        transform: translate(calc(var(--side-offset-max) * -0.7), 160%);
        max-width: 200px;

        img {
          border-radius: 2.5rem;
        }
      }

      &:nth-child(5) {
        left: 0;
        transform: translate(-40%, 10%);
        img {
          border-radius: 0 2.5rem 0rem 2.5rem;
        }
      }

      &:nth-child(6) {
        right: 0;
        transform: translate(150%, -30%);
        max-width: 190px;

        img {
          border-radius: 2.5rem 0 2.5rem 0rem;
        }
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .header {
    --header-spacer-height: 50vh;

    &__inner {
      max-width: map-get($container-sizes, xs);
    }
  }
}
// @include media-breakpoint-down(md) {
//   .is--small-gallery {
//     .header-gallery {
//       display: flex;
//       margin-inline: -$container-padding-x;
//       gap: 0.3rem;
//     }

//     .header-gallery__item {
//       position: relative;
//       img {
//         transform: revert;
//         border: none;
//       }
//       &:nth-child(1) {
//         img {
//           border-radius: 0 1rem 1rem 0;
//         }
//       }
//     }
//   }
// }
