.post-card {
  --post-primary-color: var(--bs-primary-900);
  --avatar-bg-color: #{var(--post-primary-color)};
  --chip-background-color: #{var(--post-primary-color)};

  border: 1px solid var(--bs-gray-200);
  overflow: hidden;

  .markdown-content {
    p,
    ul,
    ol {
      font-size: inherit;
    }
  }

  &__footer {
    background-color: var(--bs-gray-100);
    margin-left: -#{spacer(5)};
    margin-right: -#{spacer(5)};
    border-top: 1px solid var(--bs-gray-200);
  }

  &.type--tip {
    --post-primary-color: var(--bs-secondary);
  }

  &.type--weekopdracht {
    --post-primary-color: var(--bs-tertiary);
  }

  &.type--vraag {
    --post-primary-color: var(--bs-primary);
  }

  &__title {
    color: var(--post-primary-color);
  }

  &__type {
    color: var(--post-primary-color);
  }

  &__figure {
    img {
      border-radius: $border-radius-xl;
    }
  }
}
