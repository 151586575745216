.mat-mdc-form-field.mat-form-field--checkbox .mdc-text-field--filled:not(.mdc-text-field--disabled) {
  border: none;
  border-radius: 0;
  padding: 0;
}

.mat-mdc-form-field.mat-form-field--checkbox {
  .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
    padding-top: 7px;
    padding-bottom: 7px;
    min-height: fit-content;
  }

  .mat-mdc-form-field-focus-overlay {
    border-radius: var(--form-border-radius);
  }
}

.mat-mdc-checkbox label {
  a {
    @extend .link;
  }
}
