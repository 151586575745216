@each $name, $value in $icon-sizes {
  .icon-#{$name} {
    width: $value;
    height: $value;

    svg {
      @include size(rem($value));
    }
  }
}
 