body {
  --chip-background-color: var(--bs-secondary);
  --chip-color: white;
  --chip-size: #{map-get($chip-sizes, 'sm')};
}

.chip {
  @include size(#{var(--chip-size)});
  background: var(--chip-background-color);
  color: var(--chip-color);
  fill: var(--chip-color);
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  text-align: center;

  .icon {
    width: 50%;
    height: 50%;
    margin: auto;
  }

  // @each $name, $color in $theme-colors {
  //   &.chip--#{$name} {
  //     --chip-background-color: #{$color};
  //     --chip-background-color-alpha: #{rgba($color, 0.09)};
  //   }
  // }
}

@each $name, $colors in $chip-styles {
  $font-color: nth($colors, 1);
  $bg-color: nth($colors, 2);

  .chip--#{$name} {
    --chip-background-color: #{$bg-color};
    --chip-background-color-darker: #{darkenColor($bg-color, 0.2)};
    --chip-background-color-alpha: #{alpha($bg-color, 0.2)};
    --chip-color: #{$font-color};
  }
  .chip--#{$name} {
    fstr-icon {
      @extend .chip--#{$name};
    }
  }
}

@each $name, $value in $chip-sizes {
  .chip--#{$name} {
    --chip-size: #{rem($value)};
    // --button-padding-x: #{rem($value * 0.5)};

    // @include get-font-size(body-#{$name});

    // &.button-icon {
    //   @include size(rem($value));
    //   min-width: rem($value);
    // }
  }
}
