.title-circle-background {
  position: relative;

  > * {
    position: relative;
    z-index: 2;
  }

  &::before {
    content: '';
    @include abs(-4rem, 0, 0, 0);
    background: url('/assets/gfx/light-blue-curly-images-bg.svg') top center no-repeat;
    z-index: 1;
    aspect-ratio: 1/1;
  }
}

.post-image-card-grid {
  --grid-items-height: 350px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr; /* fr instead of % */
  grid-auto-rows: auto auto auto auto; /* auto-rows instead of template-rows */
  grid-auto-flow: dense; /* fill all the space */
  gap: spacer(3);

  @include media-breakpoint-down(md) {
    --grid-items-height: 230px;
  }

  &__item {
    grid-column: span 3; /* I will take 2 rows */

    @include media-breakpoint-down(md) {
      &:nth-child(6n + 2) {
        grid-column: span 6;
      }
    }

    @include media-breakpoint-up(md) {
      grid-column: span 2;

      &:nth-child(3n + 1) {
        grid-column: span 4;
      }

      &:nth-child(6n + 1) {
        grid-column: span 4;
      }

      &:nth-child(6n - 1),
      &:nth-child(6n) {
        grid-column: span 3;
      }
    }

    &.is--placeholder {
      border-radius: 0rem 2.5rem;
      background-color: var(--bs-secondary);
      display: flex;
      justify-content: center;
      height: var(--grid-items-height, 350px);

      @include media-breakpoint-down(md) {
        &:nth-child(even) {
          display: none;
        }
      }
      @include media-breakpoint-up(md) {
        &:nth-child(odd) {
          display: none;
        }
      }

      fstr-icon {
        width: 40%;
        color: white;
      }
    }
  }
}
